/*
|--------------------
|      Testimonials
|--------------------
*/
.testimonials-slider {
    .swiper-slide {
        display: flex;
        height: auto;
    }
}

.card-testimony {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid rgba(black, 0.04);
    box-shadow: 0px 0px 15px 0px rgba(black, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .picture {
        border-radius: 50%;
        height: 45px;
        width: 45px;
        object-fit: cover;
    }
}

.card-testimony .author {
    display: flex;
    align-items: center;

    > div:first-child {
        width: 55px;
    }
}
