.secteur-section-img {
    img {
        height: 100%;
        object-fit: cover;
        min-height: 250px;
        max-height: 800px;
        width: 100%;

        @include media-breakpoint-up(md) {
            min-height: 400px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 800px;
        }
    }

    &.right {
        .row {
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
        }
    }
}
