#page-techno .banner,
#page-techno .avantages {
    ul > li {
        @extend .has-mobius;
        margin-bottom: 10px;
        &:before {
            margin-right: 40px;
        }
    }
}
#page-techno .avantages {
    ul > li {
        // display: flex;
    }
}

#page-techno .fonctions.first {
    .container {
        background-color: white;
        position: relative;
        z-index: 2;
        border-radius: 5px;
        @include media-breakpoint-up(md) {
            transform: translateY(-100px);
        }
    }
}
