/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

//Roboto on head.twig

@font-face {
    font-family: "haas";
    src: url("../fonts/neue-haas/NHaasGroteskDSPro-55Rg.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "haas";
    src: url("../fonts/neue-haas/NHaasGroteskDSPro-65Md.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}
