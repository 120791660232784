/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  opacity: 0;
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  // overflow-x: hidden;
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  color: $blue;
}

img,
iframe {
  max-width: 100%;
}

a,
img,
button {
  display: inline-block;
}
p {
  margin-bottom: $sm;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;
  padding-inline-start: 40px;
  li {
    // @extend .has-mobius;
    list-style-type: none;
  }
}

.container-r {
  @media (min-width: 1456px) {
    margin-right: 0;
    margin-left: calc(50vw - 728px);
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }
}
