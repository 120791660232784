/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input,
textarea,
select {
    -webkit-appearance: none;
    border-radius: 0;

    &:focus {
        outline: none;
    }
}
input[type="file"] {
    cursor: pointer;
    opacity: 0;
}

select {
    background-image: url("../img/icon/bottom.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 15px) center;
    border: 0;
    &.custom {
        cursor: pointer;
        height: 80px;
        width: 100%;
        padding: 0 50px 0 30px;
        text-transform: uppercase;
        background-position: calc(100% - 30px) center;
    }
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
// .gravity-form-bootstrap {
//     .gform_fields {
//         @extend .row;

//         input,
//         textarea {
//             width: 100%;
//             // background-color: transparent;
//         }
//     }
// }

/*
|
| Materialize form 
|-------------------
*/
.materialize-form {
    .materialize-form-group {
        position: relative;
        margin-bottom: 25px;

        label {
            position: absolute;

            top: 0;
            left: 15px;

            font-weight: 400;
            font-size: 18px;
        }
        input {
            margin-bottom: 35px;

            padding: 7px 0;

            border: 0;
            border-bottom: 2px solid $light-grey;
        }

        &.gfield_error {
            label {
                top: -20px;
            }
        }
    }
}

/*
|
| Contact form 
|---------------
*/

.base-input {
    background-color: $grey;
    border: 1px solid $blue;
    transition: 0.4s;
    &:focus {
        border-color: $orange;
        transition: 0.4s;
    }
}
.contact-form {
    .gfield_label {
        color: $blue;
        text-transform: uppercase;
    }

    .gfield_select,
    .gfield input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
    textarea,
    .ginput_container_fileupload {
        @extend .base-input;
    }

    .ginput_container_fileupload {
        position: relative;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='24px' viewBox='0 0 20 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-669.000000, -3806.000000)' fill='%23EC6F04' fill-rule='nonzero'%3E%3Cg transform='translate(-2.000000, 2793.000000)'%3E%3Cg transform='translate(191.000000, 966.000000)'%3E%3Cg transform='translate(480.324048, 47.975000)'%3E%3Cpath d='M9.5175,16.4475 C9.405,16.4475 9.315,16.4025 9.225,16.335 L5.1075,12.195 C4.95,12.0375 4.95,11.79 5.1075,11.61 C5.265,11.4525 5.5125,11.4525 5.6925,11.61 L9.135,15.0525 L9.135,0.405 C9.135,0.18 9.315,0 9.54,0 C9.765,0 9.945,0.18 9.945,0.405 L9.945,15.0075 L13.3875,11.6325 C13.545,11.475 13.7925,11.475 13.95,11.6325 C14.1075,11.79 14.1075,12.0375 13.95,12.195 L9.81,16.335 C9.7425,16.4025 9.63,16.4475 9.5175,16.4475 Z' id='Path'%3E%3C/path%3E%3Cpath d='M9.54,22.05 C4.275,22.05 0,17.775 0,12.51 C0,8.3475 2.655,4.7025 6.615,3.4425 C6.8175,3.375 7.065,3.4875 7.1325,3.7125 C7.2,3.915 7.0875,4.1625 6.8625,4.23 C3.24,5.4 0.81,8.73 0.81,12.5325 C0.8325,17.325 4.725,21.24 9.54,21.24 C14.355,21.24 18.2475,17.325 18.2475,12.5325 C18.2475,8.73 15.8175,5.4 12.195,4.23 C11.9925,4.1625 11.8575,3.9375 11.925,3.7125 C11.9925,3.51 12.2175,3.375 12.4425,3.4425 C16.4025,4.7025 19.0575884,8.37 19.0575884,12.51 C19.08,17.775 14.805,22.05 9.54,22.05 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 20px 100%;
        background-repeat: no-repeat;
        background-position: 95% center;
    }
    .gform_fileupload_rules {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0 !important;
        padding: 0 20px;
    }
    .gform_body {
        margin-bottom: 20px;
    }

    .gform_footer {
        text-align: right;
    }

    input[type="submit"] {
        @extend .button;
        position: relative;
        background-color: $orange;
        color: $white;
        // padding: 15px 30px;
        transition: all 0.3s ease-out;
        // text-transform: uppercase;
        cursor: pointer;
        border: none;
        // border-radius: 2px;

        &:hover {
            color: $white;
            background-color: darken($orange, 10%) !important;
            transition: all 0.3s ease-out;
        }
    }

    .gform_confirmation_message {
        color: $blue;
    }

    .validation_message {
        margin-top: 5px;
        color: #bb0b0b;
        font-size: 14px;
    }
}

.validation_error {
    margin-bottom: 30px;
    color: #bb0b0b !important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.gfield_consent_label,
.gfield_checkbox label,
.gfield_radio label {
    display: inline !important;
    position: relative;
    padding-left: 35px;
    font-size: 14px;
    letter-spacing: normal;
    text-transform: none;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url("../img/checkbox.svg");
        background-color: $grey;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 4px;
        width: 12px;
        height: 12px;
        background-color: $blue;
        opacity: 0;
        transition: opacity 0.4s;
    }
}

.gfield_radio label {
    &:before {
        top: 3px;
        background-image: url("../img/radio.svg");
        border-radius: 50%;
    }
    &:after {
        top: 6px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
}
.ginput_container_consent,
.gfield_checkbox,
.gfield_radio {
    input:checked ~ label:after {
        opacity: 1;
    }
}
.ginput_container_consent input,
.gfield_checkbox input {
    position: absolute;
}
