/*
|--------------------
|       Nous rejoindre
|--------------------
*/

#page-rejoindre {
    .banner-img-wrapper {
        position: relative;
        z-index: 2;
        transform: translateY(-100px);
        margin-bottom: -100px;
        @include media-breakpoint-up(xl) {
            transform: translateY(-150px);
            margin-bottom: -150px;
        }
    }
    .banner-img {
        min-height: 200px;
        padding-bottom: 50%;
    }
    .card-bloc-icon {
        min-height: 380px;
    }
}

#page-rejoindre .contact-form {
    border-radius: 5px;
    transform: translateY(-160px);
    margin-bottom: -100px;

    &.bg-blue {
        label,
        legend,
        .gfield_required {
            color: $white;
        }
    }
    .gfield {
        position: relative;
    }

    .gfield_description {
        color: $orange;
        text-transform: uppercase;
        font-size: 12px;
        text-align: right;
    }
}
