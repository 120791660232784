/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
    @extend .bg-cover;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    background-color: $blue;
    padding-top: 90px;
    overflow: hidden;

    &:before {
        content: "";
        // background: rgba(0, 0, 0, 0.2);
        background: linear-gradient(189.8deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    .item-content {
        position: relative;
        z-index: 4;
        width: 100%;
        padding: 100px 0;
    }
    ul {
        padding-left: 30px;
        li {
            @extend .has-mobius;
            &:before {
                // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 612 603' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg transform='translate(-980.000000, 237.000000)'%3E%3Cg transform='translate(0.000000, -0.325000)'%3E%3Cg transform='translate(980.983519, -236.500000)'%3E%3Cpath d='M90.9299747,333.228187 L134.187651,194.943552 C161.575911,107.323767 244.274972,55.3204326 318.921655,78.7375485 L513.45081,139.817086 C497.594115,105.570988 470.352146,78.9351344 434.548961,67.653617 L236.82048,5.61801831 C162.186518,-17.8118451 79.4747364,34.1787414 52.0991975,121.817648 L8.85424178,260.083162 C-18.5467391,347.728442 19.7815596,437.681017 94.4091615,461.200112 L97.5448819,462.156173 C80.3461232,424.997277 76.7079243,378.806792 90.9299747,333.228187' fill='%23FFF'%3E%3C/path%3E%3Cpath d='M516.62533,140.822225 L513.451446,139.834295 C530.669287,176.993192 534.294764,223.145434 520.091796,268.755908 L476.795956,406.995926 C449.433138,494.666702 366.734077,546.689157 292.112836,523.297536 L97.5709599,462.154261 C113.421295,496.400359 140.707787,523.087203 176.510972,534.330478 L374.20765,596.391572 C448.797089,619.802314 531.597918,567.786232 558.960736,480.166447 L602.154807,341.856317 C629.555788,254.249279 591.291095,164.239341 516.62533,140.822225' fill='%23FFF'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important; // white
                position: absolute;
                left: -25px;
                top: 4px;
            }
            margin-bottom: 10px;
        }
    }
}

.banner .breadcrumb {
    position: absolute;
    z-index: 4;
    top: 100px;
    left: 0;
    right: 0;
}
.breadcrumb {
    color: $blue;

    a {
        font-weight: 600;
        text-decoration: underline;
        margin-right: 5px;
    }
    .breadcrumb_last {
        margin-left: 5px;
    }
    &.white * {
        color: $white;
    }
}

.banner .mobius {
    position: absolute;
    z-index: 2;
    right: -300px;
    bottom: -20%;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transition: 1s clip-path;

    // svg path {
    //     fill: $white;
    //     opacity: 0.2;
    //     stroke-width: 2px;
    //     fill: none;
    //     stroke-miterlimit: 10;
    // }
    svg path {
        opacity: 0.2;
        stroke: white;
        stroke-width: 2px;
        fill: none;
        stroke-miterlimit: 10;
    }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: block;
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {
        .date {
            color: rgba($very-dark-grey, 0.6);
        }
    }

    /*
    * Hover state
    */
    &:hover {
        .card-img-container {
            .card-img {
                transform: scale(1.05);
            }
        }
    }
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| CMS
|------
*/
#page-cms .cms img {
    width: unset;
}

.cms {
    span {
        display: inline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 30px;
        font-weight: 600;
    }

    h1 {
        @extend .title-lg;
    }
    h2 {
        @extend .title-md;
    }
    h3 {
        @extend .title-md;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    strong {
        font-weight: 800;
    }
    a {
        text-decoration: underline;
    }
    p {
        font-size: $font-size-sm;
        margin-bottom: 20px;
    }

    li {
        list-style: initial;
    }

    &.cms-no-break {
        p {
            margin-bottom: 0;
        }
    }
}
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
    text-align: center;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
}
.wp-block-embed.w-100 iframe {
    width: 100%;
}
/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

/*
|
| Title
|---------------
*/
.has-line {
    position: relative;

    &:after {
        content: "";
        display: block;
        height: 1px;
        background-color: currentColor;
        margin-top: 8px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            width: 500px;
        }
    }

    @include media-breakpoint-up(md) {
        display: inline-block;
    }
}
.has-line-orange {
    @extend .has-line;
    &:after {
        background-color: $orange;
    }
}

.has-mobius {
    position: relative;
    // display: inline-flex;
    // padding-left: 30px;
    &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 612 603' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg transform='translate(-980.000000, 237.000000)'%3E%3Cg transform='translate(0.000000, -0.325000)'%3E%3Cg transform='translate(980.983519, -236.500000)'%3E%3Cpath d='M90.9299747,333.228187 L134.187651,194.943552 C161.575911,107.323767 244.274972,55.3204326 318.921655,78.7375485 L513.45081,139.817086 C497.594115,105.570988 470.352146,78.9351344 434.548961,67.653617 L236.82048,5.61801831 C162.186518,-17.8118451 79.4747364,34.1787414 52.0991975,121.817648 L8.85424178,260.083162 C-18.5467391,347.728442 19.7815596,437.681017 94.4091615,461.200112 L97.5448819,462.156173 C80.3461232,424.997277 76.7079243,378.806792 90.9299747,333.228187' fill='%2319255A'%3E%3C/path%3E%3Cpath d='M516.62533,140.822225 L513.451446,139.834295 C530.669287,176.993192 534.294764,223.145434 520.091796,268.755908 L476.795956,406.995926 C449.433138,494.666702 366.734077,546.689157 292.112836,523.297536 L97.5709599,462.154261 C113.421295,496.400359 140.707787,523.087203 176.510972,534.330478 L374.20765,596.391572 C448.797089,619.802314 531.597918,567.786232 558.960736,480.166447 L602.154807,341.856317 C629.555788,254.249279 591.291095,164.239341 516.62533,140.822225' fill='%2394959A'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        width: 15px;
        margin-right: 10px;
        // transition: 0.4s;
    }

    &:hover:before {
        // opacity: 0;
        // transition: 0.4s;
    }
}
.has-mobius-inverse {
    @extend .has-mobius;
    &:before {
        transform: rotate(180deg);
    }
}

/*
|
| Swiper
|---------------
*/

.swiper-button-lock {
    display: none !important;
}
.swiper-button-disabled {
    opacity: 0.4;
}
.swiper .navigation {
    gap: 10px;
    display: flex;
    justify-content: flex-end;

    .navigation-left,
    .navigation-right {
        background-color: $white;
        box-shadow: 0px 0px 15px 0px rgba(black, 0.1);
        height: 45px;
        width: 45px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        svg {
            pointer-events: none;
            height: 10px;
        }

        &:not(.swiper-button-disabled):hover {
            background-color: $orange;
            transition: 0.4s;

            svg g {
                stroke: $white;
            }
        }
    }
}
.swiper-pagination-bullet {
    position: relative;
    background: $blue !important;
    opacity: 1 !important;
    width: 5px !important;
    height: 5px !important;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 10px) !important;

    &.swiper-pagination-bullet-active:before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        border: 1px solid;
        transform: translate(-50%, -50%);
    }
}

.callToAction {
    background-image: $mobiusOpacity;
    overflow: hidden;
    background-size: 35vw;
    background-position: 75vw 115%;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md) {
        background-position: 75vw 10vw;
    }
}

/*
blanc
opacity
blue opaque
*/
.mobius-bg {
    background-size: 500px;
    background-repeat: no-repeat;
}
.mobius-bl {
    @extend .mobius-bg;
    background-position: -200px 130%;
}
.mobius-br {
    @extend .mobius-bg;
    background-position: 100% 130%;
}

.mobius-w-bl {
    @extend .mobius-bl;
    background-image: $mobiusWhite;
}
.mobius-o-bl {
    @extend .mobius-bl;
    background-image: $mobiusOpacity;
}

.mobius-o-br {
    @extend .mobius-br;
    background-image: $mobiusOpacity;
}

.anchor {
    position: relative;
    top: -30px;
}
