/*
|--------------------
|       About
|--------------------
*/

#page-about {
    .about-content ul {
        padding-inline-start: 40px;
        li {
            list-style: disc;
        }
    }

    .swiper .navigation {
        > span {
            background-color: $orange;

            svg path {
                stroke: $white;
            }
        }
    }
}
