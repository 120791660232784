/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
    .item-link {
        transition: 0.4s;
        &:hover {
            color: $orange;
            transition: 0.4s;
        }
    }
    ul {
        padding-inline-start: 0;
        li {
            list-style: none;
        }
    }
}

.footer-copyrights {
    background-color: $grey;
}

.footer-links {
    @include media-breakpoint-up(lg) {
        display: flex;

        li:not(:last-child):after {
            content: "•";
            padding: 5px;
        }
    }

    .item-link:hover {
        color: $orange;
    }

    .footer-cookies {
        a {
            color: $light-grey !important;
            border: 0 !important;
            padding: 0 !important;
            font-size: unset !important;
            &:hover {
                color: $orange !important;
                background-color: unset !important;
            }
        }
    }
}

/*
|
| Newsletter
|---------
*/
.newsletter {
    position: relative;
    background-size: cover;
    background-position: bottom;
    min-height: 200px;
    display: flex;
    align-items: flex-end;

    &:before {
        content: "";
        background-image: $mobius;
        background-repeat: no-repeat;
        background-position: 130% -15vw;
        background-size: 35vw;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .title {
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    @include media-breakpoint-up(md) {
        height: 350px;
    }
    @include media-breakpoint-up(lg) {
        height: 500px;
    }

    > .container {
        @include media-breakpoint-down(sm) {
            padding: 10px;
        }
    }
}

.newsletter-wrapper {
    background-color: $blue;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: translateY(50%);
}

.newsletter-input {
    position: relative;
    background-color: white;
    width: 400px;
    max-width: 100%;
    border-radius: 2rem;
    padding: 15px;
    display: flex;
    align-items: center;
    height: 55px;
    overflow: hidden;

    input[type="text"] {
        border: 0 !important;
        width: calc(100% - 50px) !important;
        height: 55px;
    }
    form {
        // display: flex;
    }

    .gform_wrapper {
        width: 100%;
    }
    .gfield_label,
    .gform_validation_errors,
    .gfield_validation_message {
        display: none !important;
    }
    .gform_footer {
        padding: 0 !important;
        margin: 0 !important;

        input {
            margin-bottom: 0 !important;
        }
    }

    .gform_confirmation_message {
        font-size: 12px;
    }
}

.newsletter-input .gform_footer {
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: $orange;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    // cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url("../img/icon/newsletter-icon.svg");
    background-size: 25px;
    background-repeat: no-repeat;
}

.newsletter-rgpd {
    color: $white;
    font-size: 12px;
    max-width: 600px;
    margin: 0 auto;
    padding-top: $sm;

    a {
        color: $white;
        text-decoration: underline;
    }
}

#footer #backTop {
    transition: 0.6s;
    &:hover {
        color: $orange;
        transition: 0.6s;
    }
}

#footer .social {
    svg {
        height: 20px;
        width: 20px;
    }
}

#footer .logos {
    .logo-item {
        height: 50px;
    }
}

/*Cookies*/
#ot-sdk-btn-floating.ot-floating-button {
    display: none !important;
}
