/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
    // text-transform: uppercase;
    color: $blue;
    font-size: 14px;
    font-weight: 400;
    transition: 0.4s;
    &:hover {
        color: $orange;
        // text-shadow: 1px 0 0 currentColor;
        transition: 0.4s;
    }
}

#header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $white;

    .header-container {
        @extend .container;
        position: relative;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        // padding-top: 15px;
        // padding-bottom: 15px;

        .item-logo {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .item-nav {
            display: none;

            .item-menu {
                display: flex;
                align-items: center;
                gap: 15px;

                > li {
                    // padding: 10px;
                    &:not(:last-child) {
                        // margin-right: 20px;
                    }

                    .item-link {
                        @extend .link-menu;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                display: flex;
                // align-items: center;

                .item-menu {
                    margin-right: 25px;
                }
            }
        }
    }

    .lang-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        // top: 1px;
        @extend .link-menu;
        cursor: pointer;
        text-transform: uppercase;

        ul {
            position: absolute;
            z-index: 1;
            top: 60%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
        }

        &:hover {
            ul {
                opacity: 1 !important;
                visibility: visible !important;
                transform: translateX(0) !important;
            }
        }
    }

    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        //width: 56px;
        //height: 56px;
        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & > .item-burger {
            display: block;
            width: 45px;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $very-dark-grey;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 5px 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}
#header ul > li.menu-item-has-children.opened {
    .nav-drop {
        opacity: 1;
        pointer-events: initial;
        transition-delay: 0s;
    }

    li {
        opacity: 1;
        transform: translateY(0);
    }
}

#header .nav-drop {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $grey;
    transition: 1s;

    li {
        opacity: 0;
        transform: translateY(20px);
        transition: 1s;
        transition-delay: 0.2s;
    }
    &.opened {
        opacity: 1;
    }
    // transition-delay: 0.5s;
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: $white;
    // overflow: auto;
    opacity: 0;
    visibility: hidden;

    .item-container {
        @extend .container;

        > ul {
            position: absolute;
            // inset: 0;
            inset: 100px 0px 70px 0px;
            padding-left: 30px;
            padding-right: 30px;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // padding-top: 100px;
            // padding-bottom: 70px;
            overflow: auto;
            & > li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                > a {
                    // text-transform: uppercase;
                    color: $blue;
                    font-size: 30px;
                    font-weight: 500;
                    -webkit-font-smoothing: antialiased;

                    @include media-breakpoint-down(xs) {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .item-socials-container {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        .item-socials {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            a {
                font-size: 25px;

                &:not(:last-child) {
                    margin-right: 25px;
                }
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;

                a {
                    font-size: 20px;
                }
            }
        }
    }
}
#mobile-menu .nav-drop {
    display: none;
    margin: 0;
    padding: 0;
    padding-left: 20px;

    > li > a:before {
        content: "-";
        margin-right: 10px;
    }
}
#mobile-menu .item-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    > a {
        flex: 1;
    }
}

/*
|
| Boutons
|-------
*/

.floating-actions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;

    > a {
        flex: 1;
    }

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

#header .actions {
    display: none;

    a {
        position: relative;
        transition: 0.4s;
        text-align: center;
        overflow: hidden;
        span {
            position: relative;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            transition: 0.4s;
        }
        &:after {
            content: attr(data-text);
            position: absolute;
            z-index: 2;
            // top: -100%;
            top: calc(50% + 10px);
            left: 0;
            right: 0;
            margin: auto;
            color: currentColor;
            opacity: 0;
            transition: 0.4s;
        }
    }
    a:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        background-color: rgba($black, 0.1);
        z-index: 2;
        opacity: 0;
        transition: 0.6s;
        transform: translate(-50%, -50%) scale(0);
        width: 0;
        padding-top: 110%;
    }
    a:hover {
        color: $white;
        transition: 0.4s;

        &:before {
            transform: translate(-50%, -50%) scale(1);
            width: 100%;
            opacity: 1;
        }
        span {
            transform: translateY(-10px);
            opacity: 0;
        }
        &:after {
            opacity: 1;
            transform: translateY(-20px);
        }
    }

    @include media-breakpoint-up(xl) {
        display: flex;
    }
}

.current-menu-ancestor > a,
.current-menu-item > a {
    color: $orange !important;
    font-weight: 600 !important;
}
