/*
|--------------------
|      SINGLE
|--------------------
*/
#page-news-single {
    &:not(.ressources):before {
        content: "";
        // background-color: $grey;
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // z-index: -1;
        // height: 80vh;
        // max-height: 800px;
    }
}
#page-news-single .cms {
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }

    ul {
        padding-left: 40px;
        li {
            list-style: circle;
        }
    }
}

blockquote {
    position: relative;

    cite {
        display: flex;
        width: 100%;
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

/*
|--------------------
|      SECTEUR
|--------------------
*/

#single-secteurs ul {
    padding-left: 30px;

    li {
        @extend .has-mobius;
        &:before {
            position: absolute;
            left: -25px;
            top: 4px;
        }
        margin-bottom: 10px;
    }
}

/*
|--------------------
|      PRODUCT
|--------------------
*/
#page-product-single {
    overflow: hidden;
}
#page-product-single ul {
    padding-left: 30px;

    li {
        @extend .has-mobius;
        &:before {
            position: absolute;
            left: -25px;
            top: 2px;
        }
        margin-bottom: 10px;
    }
}

.single-product-banner {
    // overflow-x: clip;
    img {
        height: auto;
        width: 800px;
        max-height: 400px;
        object-fit: contain;
    }
    .mobius {
        position: absolute;
        width: 300px;
        right: -100px;
        bottom: -100px;
        z-index: -1;
        // pointer-events: none;

        @include media-breakpoint-up(md) {
            bottom: -145px;
            width: 500px;
        }
        @include media-breakpoint-up(xl) {
            width: 42.5vw;
            min-width: 600px;
            min-height: 600px;
            bottom: -12vw;
            right: -10%;
        }
    }
}

#page-product-single .problems .container {
    @include media-breakpoint-up(lg) {
        position: relative;
        z-index: 2;
        transform: translateY(150px);
        margin-bottom: 250px;
    }
}

#page-product-single .problems-slider .swiper-wrapper,
#page-product-single .advantages-slider .swiper-wrapper,
#page-product-single .products-slider .swiper-wrapper {
    @include media-breakpoint-up(xl) {
        // justify-content: center;
    }
}

#page-product-single .presentation {
    .swiper-slide {
        height: auto;
    }
    .swiper-slide img {
        height: 300px;
        max-height: 700px;
        width: 100%;
        object-fit: cover;
        @include media-breakpoint-up(xl) {
            height: 100%;
        }
    }
}

#page-product-single .presentation .navigation {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 2;

    .navigation-left {
        position: absolute;
        left: 0;
    }
    .navigation-right {
        position: absolute;
        right: 0;
    }
}

#page-product-single .techniques {
    .tech-item {
        border-bottom: 1px solid rgba($blue, 0.2);
    }
}

.etudes {
    .study-item {
        border-bottom: 1px solid rgba($blue, 0.2);
    }
}

/*
|--------------------
|      LANDING
|--------------------
*/
.single-landing {
    #header,
    #footer .footer-primary {
        display: none;
    }
}

.single-landing-banner {
    background-color: $white;
    min-height: 80vh;
    .logo {
        height: 80px;
    }
    .form-container {
        position: relative;
        z-index: 3;
        background-color: $white;
        border-radius: 6px;
        max-width: 700px;
        margin: 0 auto;
    }
}
.single-landing-banner-left {
    display: flex;
    align-items: flex-end;
}

.single-landing-sections {
    z-index: 1;
    background-color: $grey;
    padding-top: 100px;
    padding-bottom: 200px;
    .sections-item {
        font-weight: 600;
        opacity: 0.2;
        cursor: pointer;
        transition: 0.4s all;
        &.active {
            opacity: 1;
        }
    }
    .sections-image-wrapper {
        position: relative;
        aspect-ratio: 652/509;
        .sections-image {
            position: absolute;
            inset: 0;

            .clippath {
                height: 100%;
                width: 100%;
                clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
                transition: 0.2s;
            }
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            .text {
                @include media-breakpoint-up(md) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 90%;
                    margin: 0 auto;
                    transform: translateY(50%);
                }
                background-color: $white;
                font-size: 14px;
                padding: 30px;
                opacity: 0;
                transition: 0.4s;
            }
            &.active {
                .clippath {
                    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
                    transition: 0.6s;
                }
                .text {
                    opacity: 1;
                }
            }
        }
    }
}
