/*
|
| CARD POST
|--------------
*/
.card-post {
    display: block;
    margin-bottom: 32px;

    .more {
        font-size: 11px;
        letter-spacing: 1px;
    }
}

.card-post-image {
    position: relative;
    height: 200px;
    background-color: $black;
    overflow: hidden;
    .badge {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: $white;
        color: $blue;
        padding: 6px 20px;
        border-radius: 2rem;
        font-size: 10px;
        text-transform: uppercase;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: 1s;
    }

    &:hover {
        img {
            transform: scale(1.2);
            transition: 1s;
        }
    }
}

.card-post.horizontal {
    display: flex;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .card-post-image {
        @include media-breakpoint-up(sm) {
            flex: 1;
        }
    }

    .card-post-content {
        flex: 1.4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
}

.card-post:not(.horizontal) {
    .card-post-content {
        @include media-breakpoint-up(md) {
            min-height: 150px;
        }
    }
}

.card-post.white {
    .card-post-content * {
        color: $white;
    }
}

/*
|--------------------
|      CARD BLOC
|--------------------
*/
.card-bloc {
    display: block;
}

.card-bloc-image {
    position: relative;
    height: 280px;
    border-radius: 5px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.8s;
    }
}
.card-bloc-image:hover img {
    transform: scale(1.2);
    transition: 0.8s;
}
.card-bloc-content {
    min-height: 170px;
}

/*
|--------------------
|      CARD BLOC WITH ICON
|--------------------
*/
.card-bloc-icon {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    background-color: $white;
    margin-bottom: $md;
}

.card-bloc-icon {
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        min-height: 480px;
    }

    img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
}

.card-bloc-icon .icon {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-bloc-icon .item-content {
    font-size: 16px;
}

/*
|--------------------
|  CARD TEAM
|--------------------
*/

.card-team {
    position: relative;
    height: 400px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.6s;
    }

    .hover {
        background-image: url("data:image/svg+xml,%3Csvg width='612px' height='603px' viewBox='0 0 612 603' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='opacity:0.2;'%3E%3Cg transform='translate(-980.000000, 237.000000)'%3E%3Cg transform='translate(0.000000, -0.325000)'%3E%3Cg transform='translate(980.983519, -236.500000)'%3E%3Cpath d='M90.9299747,333.228187 L134.187651,194.943552 C161.575911,107.323767 244.274972,55.3204326 318.921655,78.7375485 L513.45081,139.817086 C497.594115,105.570988 470.352146,78.9351344 434.548961,67.653617 L236.82048,5.61801831 C162.186518,-17.8118451 79.4747364,34.1787414 52.0991975,121.817648 L8.85424178,260.083162 C-18.5467391,347.728442 19.7815596,437.681017 94.4091615,461.200112 L97.5448819,462.156173 C80.3461232,424.997277 76.7079243,378.806792 90.9299747,333.228187' fill='%23fff'%3E%3C/path%3E%3Cpath d='M516.62533,140.822225 L513.451446,139.834295 C530.669287,176.993192 534.294764,223.145434 520.091796,268.755908 L476.795956,406.995926 C449.433138,494.666702 366.734077,546.689157 292.112836,523.297536 L97.5709599,462.154261 C113.421295,496.400359 140.707787,523.087203 176.510972,534.330478 L374.20765,596.391572 C448.797089,619.802314 531.597918,567.786232 558.960736,480.166447 L602.154807,341.856317 C629.555788,254.249279 591.291095,164.239341 516.62533,140.822225' fill='%23fff'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-position: -200%;
        background-repeat: no-repeat;
        background-color: $orange;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        opacity: 0;
        pointer-events: none;
        transition: 0.8s;
    }
}

.card-team:hover {
    .hover {
        opacity: 1;
        transition: 0.8s;
    }
}

/*
|--------------------
|  CARD PRODUCT
|--------------------
*/
.card-product {
    position: relative;
    width: 100%;
    &.white * {
        color: $white;
    }
}

.card-product-image {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    img {
        // height: auto !important;
        max-height: 300px;
        width: 100%;
        object-fit: contain;
    }

    @include media-breakpoint-up(lg) {
        height: 400px;
    }
}

.card-product-image .tags {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .tag {
        border-radius: 30px;
        font-size: 11px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.4rem 1rem;
    }
}

/*
|--------------------
|  CARD PROBLEMS
|--------------------
*/
.card-problem {
    position: relative;
    overflow: hidden;
    box-shadow: $boxShadow;

    strong {
        color: var(--product-color);
    }
}
.card-problem-image {
    height: 165px;
    background-size: cover;
    padding-bottom: 10%;
    background-color: $white;
}
.card-problem-content {
    min-height: 150px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/*
|--------------------
|  CERTIFICATION
|--------------------
*/
.card-certification {
    position: relative;
    display: block;
    align-items: center;
    gap: 22px;
    min-height: 100px;
    // width: 100%;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    img {
        width: 80px;
        max-height: 75px;
    }
}

/*
|--------------------
|  TECHNO
|--------------------
*/
.card-techno {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;

    @include media-breakpoint-down(md) {
        height: 150px;
    }

    &:hover {
        .icon {
            transform: scale(1.1);
            transition: 0.6s;
        }
    }
}

.card-techno .icon {
    background-color: $grey;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.6s;
    img {
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
}

.card-techno .lien {
    color: $orange;

    &:hover {
        color: darken($orange, 10%);
        transition: 0.4s;
    }
}

/*
|--------------------
|  BLOC IMAGE
|--------------------
*/
.bloc-image {
    position: relative;
    margin-bottom: $md;

    @include media-breakpoint-up(md) {
        max-height: 500px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: $xl;
    }
}
.bloc-image .image {
    height: 200px;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        height: 400px;
    }
    @include media-breakpoint-up(xl) {
        height: 450px;
    }

    > div {
        flex: 0.6;
        height: 100%;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.bloc-image .content {
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        flex: 0.5;
        padding: 55px 100px 55px 100px;
    }
    @include media-breakpoint-up(lg) {
        min-height: 400px;
        display: flex;
        flex-direction: row-reverse;
        transform: translate(0, -80%);
    }
}

.bloc-image.left {
    .mob {
        background-image: $mobiusOpacity;
        background-size: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-position: 20vw 10vh;
    }
}

.bloc-image.right {
    @include media-breakpoint-up(lg) {
        // margin-bottom: -220px;
    }
    .image {
        flex-direction: row-reverse;
    }
    .content {
        flex-direction: row;
    }
    .mob {
        background-image: $mobiusOpacity;
        background-size: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-position: -20vw 0;
    }
}

/*
|
| CARD RESSOURCE
|--------------
*/
.card-ressource {
    position: relative;
    margin-bottom: 32px;
    display: flex;

    &.horizontal {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    &:not(.horizontal) {
        min-height: 400px;
        flex-direction: column;

        justify-content: space-between;
    }

    .more {
        font-size: 11px;
        letter-spacing: 1px;
    }
}

.card-ressource-metas {
    .badge {
        display: inline-flex;
        text-transform: uppercase;
        font-size: 12px;
        padding: 6px 30px;
        border-radius: 2rem;
        background-color: $blue;
        color: $white;
    }
}
