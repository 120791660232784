/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #000000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #f6f7fc;
$light-grey: #66676b;
$very-light-grey: #fafafa;
$white: #ffffff;
$white-opacity: rgba(255, 255, 255, 0.4);
$black-opacity: rgba(0, 0, 0, 0.6);
$blue: #19255a;
$light-blue: #3399cc;
$orange: #ec6f04;
$dark-green: #366981;

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $blue;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
$colors: (
  //Required colors
  "black": $black,
  "very-dark-grey": $very-dark-grey,
  "white-opacity": $white-opacity,
  "dark-grey": $dark-grey,
  "grey": $grey,
  "light-grey": $light-grey,
  "very-light-grey": $very-light-grey,
  "white": $white,
  "blue": $blue,
  "light-blue": $light-blue,
  "orange": $orange,
  "dark-green": $dark-green
);
