/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
    /*
    |
    | Section contact
    |------------------
    */
    .section-form {
        .container {
            position: relative;
            z-index: 2;
            background-color: $white;
            border-radius: 5px;
            @include media-breakpoint-up(md) {
                transform: translateY(-100px);
            }
        }
    }
}
