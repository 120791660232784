/*
|--------------------
|      BUTTONS
|--------------------
*/

.button {
    position: relative;
    display: inline-flex;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 30px;
    border-radius: 2rem;
    overflow: hidden;
    transition: background-color 2s;
    span {
        position: relative;
        z-index: 8;
        transition: color 0.6s;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 4;
        transform: scaleX(0);
        transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform-origin: center;
    }
    &:hover {
        background-color: transparent !important; //fix
    }
    &:hover:before {
        transform: scaleX(1);
        transform-origin: center;
        transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &:hover span {
        transition: color 0.6s;
    }
}

.button.--orange {
    background-color: $orange;
    span {
        color: $white;
    }

    &:before {
        background-color: $white;
    }

    &:hover {
        span {
            color: $orange;
        }
    }
}

.button.--blue {
    background-color: $blue;
    span {
        color: $white;
    }

    &:before {
        background-color: $white;
    }

    &:hover {
        span {
            color: $blue;
        }
    }
}

.button.--white {
    background-color: $white;
    span {
        color: $blue;
    }

    &:before {
        background-color: $blue;
    }

    &:hover {
        span {
            color: $white;
        }
    }
}

.button.--white-orange {
    background-color: $white;
    span {
        color: $orange;
    }

    &:before {
        background-color: $orange;
    }

    &:hover {
        span {
            color: $white;
        }
    }
}

.button-secondary {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    font-family: $font-family-extra;
    letter-spacing: 1px;

    span {
        display: inline-block;
        text-transform: uppercase;
        font-size: 13px;
        position: relative;
        transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    span:after {
        content: attr(data-hover);
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        margin: auto;
        color: $orange;
        opacity: 0;
    }

    &:hover {
        span {
            transform: translateY(100%);
        }
        span:after {
            opacity: 1;
        }
    }
}
