#page-services {
    ul li {
        list-style: initial;
    }
}

.services-anchors {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    box-shadow: $boxShadow;
    background-color: #ffffff;
    overflow: hidden;
    transform: translateY(-50px);

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.services-anchors .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 70px;
    // opacity: 0.2;
    transition: 0.4s;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        &:not(:last-child)::after {
            content: "";
            display: block;
            width: 1px;
            height: 80%;
            background-color: rgba(0, 0, 0, 0.1);
            margin-left: 10px;
        }
    }

    &.active,
    &:hover {
        color: $orange;
        transition: 0.4s;
    }
}
