/*
|--------------------
|      NEWS
|--------------------
*/

#page-news-archive {
    background-image: $mobiusOpacity;
    background-size: 40vw;
    background-repeat: no-repeat;
    background-position: 110% -15vw;
}

/*
|
| Filters
|--------------
*/
.filters {
    display: flex;
    .active {
        color: $orange;
        // text-decoration: underline;
        transition: 0.8s;
        // font-weight: 600;
        text-shadow: 1px 0 0 $orange;
    }
    .item {
        cursor: pointer;
        transition: 0.8s;
    }

    .item:hover {
        @extend .active;
    }

    // @include media-breakpoint-up(lg) {
    //     justify-content: end;
    // }
}

.filters-label {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.load-more-container {
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}
