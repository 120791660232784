/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home {
    overflow: hidden;
}
/*
|
| Banner
|---------
*/
.home-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 90vh;
    background-color: $black;
    padding-top: 115px;
    background-size: cover;

    // transition: background 1s linear;

    @include media-breakpoint-up(md) {
        min-height: calc(100vh - 120px);
    }

    &:before {
        content: "";
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
    }
}
.home-banner .mobius {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: -10vw;
    opacity: 0.2;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transition: 2s clip-path;
    pointer-events: none;

    svg {
        height: auto;
        width: 40vw;
        transform: translateX(20vw);
    }
    svg path {
        stroke: white;
        stroke-width: 2px;
        fill: none;
        stroke-miterlimit: 10;
    }
}

.home-banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.home-banner-content {
    position: relative;
    z-index: 2;
    .item-text {
        max-width: 600px;
    }
}
.home-banner-bottom {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    bottom: -45px;

    // @include media-breakpoint-up(md) {
    //     position: absolute;
    // }
}
.swiper-banner {
    display: flex;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    background-color: $white;
    cursor: pointer;
    // border: 1px solid;
    overflow: hidden;

    .swiper-wrapper {
        // transition-duration: 0 !important;
        // transform: translate3d(-1380px, 0, 0) !important;
        // transform: translate(0px, 0px) !important;
    }
}
.swiper-banner {
    .item-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $sm;
        height: 70px;
        transition: 0.5s;
    }
    .item-slide:hover {
        background-color: $blue;
        color: $white;
        transition: 0.5s;
    }
    .swiper-slide:not(:last-child) {
        .item-slide:after {
            @include media-breakpoint-up(xl) {
                content: "";
                position: absolute;
                right: 0;
                height: 60%;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.home-banner-bottom {
    .button-prev {
        left: -20px;
    }
    .button-next {
        right: -20px;
    }
    .button-prev,
    .button-next {
        background-color: $orange;
        box-shadow: 0px 0px 15px 0px rgba(black, 0.1);
        height: 40px;
        width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);

        @include media-breakpoint-up(xl) {
            opacity: 0;
            pointer-events: none;
        }

        svg {
            pointer-events: none;
            height: 10px;
        }
    }
}

/*
|
| Alert info
|---------
*/

.alert {
    background-color: $grey;
}

.alert-wrapper {
    display: flex;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}
.alert-wrapper .image {
    flex: 2;
    min-height: 200px;

    // @include media-breakpoint-up(xl) {
    //     flex: 2;
    // }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.alert-wrapper .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 3;

    @include media-breakpoint-up(lg) {
        background-color: $white;
    }

    .text {
        min-height: 80px;
    }
}

.alert-wrapper .footer {
    width: 100%;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    > div {
        flex: 1;
    }
}

/*
|
| Nos produits
|---------
*/

.home-products {
    position: relative;
    background-color: $blue;
    background-image: $mobiusOpacity;
    background-size: 40vw;
    background-repeat: no-repeat;
    background-position: 130% -20vw;
    // overflow: hidden;

    .container {
        transform: translateY(100px);
    }
}

.card-portal {
    position: relative;
    display: block;
    cursor: pointer;
    width: 100%;
}
.card-portal:hover {
    .hover {
        opacity: 1;
        transition: 0.8s opacity;
    }
    .bg {
        transform: scale(1.1);
        transition: 0.8s;
    }
}
.card-portal-title {
    min-height: 72px;
    @include media-breakpoint-down(sm) {
        font-size: 16px !important;
        min-height: 50px;
    }
}

.card-portal-content {
    position: relative;
    overflow: hidden;
}
.card-portal-content .hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    transition: 0.8s opacity;

    background: linear-gradient(196.25deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.card-portal-content .bg {
    position: relative;
    height: 250px;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.8s;

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-up(md) {
        height: 400px;
    }
    @include media-breakpoint-up(lg) {
        height: 450px;
    }
}

/*
|
| News
|---------
*/

.home-news {
    padding-top: 200px;
    transform: translateY(-150px);
    margin-bottom: -150px;

    @include media-breakpoint-up(md) {
        // padding-top: 300px;
    }
}
