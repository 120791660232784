/*
|
| Numbers
|--------------
*/
.numbers .number {
    font-size: 100px;

    @include media-breakpoint-up(md) {
        font-size: 100px;
    }
}
